import React, { Component } from 'react';
import { Form } from 'react-bulma-components';
import { Button } from 'react-bulma-components';


export class Todo extends Component {

  constructor(props) {
    super(props);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.state = {
      items: [],
      completedItems: [],
    };
  }

  add(e) {
    const inputBox = document.getElementById('textInput');
    const newItem = inputBox.value;
    this.setState({
      items: [...this.state.items, newItem],
    });
    inputBox.value = '';
  }

  remove(e) {
    var array = [...this.state.items];
    console.log(e.target.innerHTML)
    var index = array.indexOf(e.target.innerHTML);
    array.splice(index, 1);
    this.setState({
      items: array,
    });
  }

  render() {
    const listItems = this.state.items.map((item, i) =>
      <li
        key={i}
        onClick={this.remove}
      >
      {item}
      </li>
    );

    return (
      <div>
        <Form.Label>New Item</Form.Label>
        <Form.Field className="has-addons">
          <Form.Control>
            <Form.Input id="textInput" />
          </Form.Control>
          <Form.Control>
            <Button onClick={this.add}>Add</Button>
          </Form.Control>
        </Form.Field>
        <ul>{listItems}</ul>
      </div>
    );
  }
}
