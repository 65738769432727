// import './App.css';
import 'bulma/css/bulma.min.css';
import { Section } from 'react-bulma-components';
import { Container } from 'react-bulma-components';
import { Columns } from 'react-bulma-components';
import { Hero } from 'react-bulma-components';
import { Box } from 'react-bulma-components';
import { Heading } from 'react-bulma-components';

import { Timer } from './Timer';
import { Todo } from './Todo';


function App() {
  return (
    <>
      <Hero backgroundColor="dark" className="is-fullheight">

        <Section>
          <Container>
            <Columns>
              <Columns.Column></Columns.Column>
              <Columns.Column>
                <Box>
                  <Heading className="has-text-centered">Get It Done.</Heading>
                </Box>
              </Columns.Column>
              <Columns.Column></Columns.Column>
            </Columns>

            <Columns>
              <Columns.Column></Columns.Column>
              <Columns.Column>
                <Box>
                  <Timer
                    workTime={25 * 60}
                    breakTime={5 * 60}
                  />
                </Box>
              </Columns.Column>
              <Columns.Column></Columns.Column>
            </Columns>

            <Columns>
              <Columns.Column></Columns.Column>
              <Columns.Column>
                <Box>
                  <Todo />
                </Box>
              </Columns.Column>
              <Columns.Column></Columns.Column>
            </Columns>
          </Container>
        </Section>

        <Hero.Body>
        </Hero.Body>

      </Hero>
    </>
  );
}

export default App;
