import React, { Component } from 'react';
import { Heading } from 'react-bulma-components';
import { Button } from 'react-bulma-components';
import { Progress } from 'react-bulma-components';

export class Timer extends Component {
  constructor(props) {
    super(props);
    this.toggleRunning = this.toggleRunning.bind(this);
    this.state = {
      workTime: props.workTime,
      breakTime: props.breakTime,
      time: props.workTime,
      running: false,
      onBreak: false
    };
  }

  componentDidMount() {
    this.timer = setInterval( () => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  tick() {
    if (this.state.time < 1) {
      this.reset();
    }
    const deltaTime = this.state.running ? 1 : 0;
    this.setState({
      time: this.state.time - deltaTime,
    });
  }

  toggleRunning(e) {
    this.setState({
      running: !this.state.running
    });
  }

  reset() {
    const newTime = this.state.onBreak ? this.state.workTime : this.state.breakTime
    this.setState({
      time: newTime,
      running: false,
      onBreak: !this.state.onBreak
    });
  }

  render() {
    const message = this.state.onBreak ? "Take a break!" : "Let's get productive!";
    const minutes = Math.floor(this.state.time / 60);
    const seconds = this.state.time % 60;
    const maxTime = this.state.onBreak ? this.state.breakTime : this.state.workTime;
    const buttonText = this.state.running ? "Stop" : "Start";
    return (
      <div className="has-text-centered">
        <Heading textColor="dark">{message}</Heading>
        <Heading textColor="dark">{minutes}:{seconds}</Heading>
        <Progress
          max={maxTime}
          value={this.state.time}/>
        <Button
          className="has-text-weight-bold"
          textColor="white"
          color="dark"
          onClick={this.toggleRunning}
          >
          {buttonText}
          </Button>
      </div>
    );
  }
}
